import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import todoList from "../../Assets/Projects/todolist.png";
import mip from "../../Assets/Projects/mip.png";
import pomodoro from "../../Assets/Projects/pomodoro.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="purple">Projetos </strong>
        </h1>
        <p style={{ color: "white" }}>
          Aqui estão alguns dos projetos mais recentes em que trabalhei
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mip}
              isBlog={false}
              title="MIP"
              description="O Multithreading Image Processor (MIP) é um projeto que visa desenvolver um sistema de processamento de imagem rápido e altamente personalizável usando os pacotes dart:io, dart:isolate e image da linguagem de programação Dart, bem como o pacote nyxx para bots Discord."
              ghLink="https://github.com/gzeloni/mip"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pomodoro}
              isBlog={false}
              title="Pomodoro"
              description="Pomodoro é uma ferramenta de gerenciamento de tempo que ajuda as pessoas a aumentarem sua produtividade por meio de um método de trabalho estruturado em períodos de tempo."
              ghLink="https://github.com/gzeloni/pomodoro"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={todoList}
              isBlog={false}
              title="Todo List App"
              description="Todo List é um aplicativo de tarefas que pretende ser leve, rápido e funcional, podendo ser executado em diversos dispositivos. - EM DESENVOLVIMENTO"
              ghLink="https://github.com/gzeloni/flutter_todo_list_app"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
