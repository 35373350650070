import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Olá a todos, me chamo <span className="purple">Gustavo Zeloni </span>
            de <span className="purple"> Curitiba, Paraná - Brasil.</span>
            <br />Sou desenvolvedor mobile com experiência em: Dart / Flutter e Swift.
            <br />
            <br />
            Aqui estão algumas de minhas áreas de interesse na programação:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Mobile
            </li>
            <li className="about-activity">
              <ImPointRight /> Backend
            </li>
            <li className="about-activity">
              <ImPointRight /> Machine Learning
            </li>
          </ul>

          <p style={{ color: "rgb(57 171 242)" }}>
            "Busco desenvolver coisas que façam a diferença para o mundo... ou que pelo menos sejam uteis!"{" "}
          </p>
          <footer className="blockquote-footer">Gustavo Zeloni</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
